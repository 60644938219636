import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _toUpper from 'lodash/toUpper';

import moment from 'moment';
import { Link } from 'gatsby';
import { Card, Tag, Tooltip } from 'antd';
import { EMPTY_STRING } from '../../constants/base';

import {
  cardContent,
  linkCard,
  cardList,
  readMore,
} from './blogInfoCard.module.scss';

export default function BlogCardInfo({
  categories,
  _createdAt,
  slug,
  title,
  customLinkCardClass,
  showTooltip,
}) {
  const cards = useMemo(
    () => (
      <Card className="m-t-12 m-b-12 equal-card" hoverable title={title}>
        <ul className={cardContent}>
          <li className={cardList}>
            {_map(categories, (tag) => {
              const title = _get(tag, 'title');
              const colorHexCode = _get(tag, 'colorHexCode', '#ccc');

              return (
                <Tag color={colorHexCode} key={title}>
                  {_toUpper(title)}
                </Tag>
              );
            })}
          </li>
          <li className={cardList}>
            published: {moment(_createdAt).fromNow()}
          </li>
          <li className={readMore}>read more</li>
        </ul>
      </Card>
    ),
    [categories, _createdAt]
  );

  const renderWithTooptip = useMemo(
    () =>
      showTooltip ? (
        <Tooltip
          title={title}
          color="#f5c130"
          key={`posts/${_get(slug, 'current', EMPTY_STRING)}`}
        >
          {cards}
        </Tooltip>
      ) : (
        cards
      ),
    [cards, title, showTooltip]
  );
  return (
    <Link
      className={cx(linkCard, customLinkCardClass)}
      to={`../blog-post/${_get(slug, 'current', EMPTY_STRING)}`}
    >
      {renderWithTooptip}
    </Link>
  );
}

BlogCardInfo.propTypes = {
  categories: PropTypes.array.isRequired,
  _createdAt: PropTypes.string.isRequired,
  slug: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  customLinkCardClass: PropTypes.string,
  showTooltip: PropTypes.bool,
};

BlogCardInfo.defaultProps = {
  showTooltip: true,
  customLinkCardClass: '',
};
