import React from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _map from 'lodash/map';
import BlogInfoCard from '../../components/blogInfoCard';
import Layout from '../../components/Layout';

import { blogLinks } from './blogs.module.scss';

const site = 'sumitpal.in';
const title = 'Blogs | Sumit Pal';
const description = 'List of Sumit`s published blogs';
const seo = {
  title,
  description,
  canonical: site,
  openGraph: {
    url: site,
    title,
    description,
    site_name: site,
  },
};

function Blog({ data = {} }) {
  const contents = _get(data, 'allSanityPost.nodes', []);

  return (
    <Layout seoData={seo}>
      <div className="conatiner flex flex-center flex-column m-t-120 m-b-120">
        <h2 className="weigthed-text text-brand-underline-middle">Blogs</h2>
        <section style={{ display: 'flex', flexDirection: 'column' }}>
          {_map(contents, (content, index) => {
            const { categories, publishedAt, slug, title } = content;

            return (
              <BlogInfoCard
                categories={categories}
                _createdAt={publishedAt}
                slug={slug}
                title={title}
                id={index}
                customLinkCardClass={blogLinks}
                showTooltip={false}
                key={slug?.current}
              />
            );
          })}
        </section>
      </div>
    </Layout>
  );
}

Blog.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Blog;
